<template>
    <div class="fl-y-cc full-height">
        <h1>Welcome to Catalist</h1>
    </div>
</template>

<script>

export default {
    name: 'Home',
    components: {}
};

</script>
<style scoped>

</style>
